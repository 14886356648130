<template>
  <b-modal
    id="new-campaign-package-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>Crie uma estratégia</span>
      </h2>
    </div>
    <div class="text-center mt-3">
      <p>Você está quase lá!</p>
      <p>O último passo para criação da sua campanha é criar uma estratégia de segmentação.</p>
      <p>Clique no link abaixo para ser levado para a página de criação!</p>
      <router-link
        class="btn btn-primary mt-4"
        :to="{ name: 'lines.create', params: { cId: campaign ? campaign.id : '', pId: segmentation ? segmentation.id : '' } }"
      >
        Criar uma estratégia
      </router-link>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: 'NewCampaignPackageModal',
  props: ['campaign', 'segmentation']
}
</script>
