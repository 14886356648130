<template>
  <span>
    <template v-if="!loader">
      <button
        type="button"
        class="retry-btn"
        :class="classes"
        v-b-tooltip.hover title="Ops, tivemos um problema na criação. Clique aqui pra tentar de novo."
        @click="retry"
      >
        <i class="fa fa-repeat"></i>
      </button>
    </template>
    <template v-else>
      <Loader
        :size="'small'"
        :align="'start'"
      />
    </template>
  </span>
</template>

<script>

import CampaignPackagesService from '@/modules/campaigns/services/campaign-packages-service'

import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'RetryCampaignPackageCreationOnPlatformButton',
  props: ['packageId', 'color'],
  components: {
    Loader
  },
  data () {
    return {
      loader: false
    }
  },
  computed: {
    classes () {
      const array = []
      if (this.color) array.push(`color-${this.color}`)
      return array
    }
  },
  methods: {
    /**
     * Retry
     */
    async retry () {
      this.loader = true
      try {
        await CampaignPackagesService.retryCreationOnPlatform(this.packageId)
        this.$emit('retry-successful')
      } catch (error) {
        this.$emit('retry-error', error)
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.retry-btn {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;

  &.color-white {
    color: #fff;
  }
}
</style>
